import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
// import BulkLoadDashboard from './bulk-load/BulkLoadDashboard';

export const NAME = 'transactions';

export default class TransactionsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'transaction.*';
  }

  getNavigation() {
    return [
      {
        label: 'Transactions',
        icon: 'exchange',
        path: '/transaction',
        permission: 'transactions.*',
        policy: 'Admin:Transaction:View:*'
        //   children: [
        //     {
        //       label: 'Transactions',
        //       icon: 'exchange',
        //       path: '/transaction',
        //       permission: 'transactions.*'
        //     },
        //     {
        //       label: 'Transactions Monitoring',
        //       icon: 'table',
        //       path: '/transaction/monitoring',
        //       permission: 'transactions.monitoring.*'
        //     }
        //   ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}transaction`}
        load={() => import('./TransactionsNavigation')}
      />
      // <AppRoute path="/transactions/bulk-load" component={BulkLoadDashboard} />
    ];
  }
}
