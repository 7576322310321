import { AppBadge, ModalDialog, SwitchButton } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import { capitalize } from 'lodash';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
// import DeleteButton from '@launchpad/components/crud/DeleteButton';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UsersModel from './models/UsersModel';
import BreadcrumbHelper from '../../../../@launchpad/util/BreadcrumbHelper';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
  history: any;
  justTable?: boolean;
}

export default class Users extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {};

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Users'
      }
    ]);
  }

  onDeleteItem(id) {
    new UsersModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'You can not delete this user!'
        });
      });
  }

  // onChangeMarketingConsentStatus(userId, isEnabled) {
  //   new UsersModel()
  //     .changeMarketingConsentStatus(userId, isEnabled)
  //     .then(() => {
  //       this._tableComponent._refresh();
  //     })
  //     .catch(() => {
  //       this.setState({
  //         showInfoModal: true,
  //         infoModalContent: 'Something went wrong!'
  //       });
  //     });
  // }

  onChangeItemStatus(user) {
    let status = '';

    if (user.status === 'active') {
      status = 'DISABLED';
    } else status = 'ACTIVE';

    new UsersModel()
      .changeUserStatus(user.id, status)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'Something went wrong!'
        });
      });
  }

  _renderEnableDisableSwitch(item) {
    return (
      <SwitchButton
        onChange={() => this.onChangeItemStatus(item)}
        isChecked={item.status === 'active'}
      />
    );
  }

  // _renderUserRole(item) {
  //   const notRegularUser = item.roles.find(
  //     ur => ur.role && ur.role.name !== 'user'
  //   );
  //   const userRole = notRegularUser
  //     ? notRegularUser.role.name.replace('-', ' ')
  //     : 'cardholder';
  //   return <span>{userRole}</span>;
  // }

  _renderStatus(item) {
    switch (item.kycStatus) {
      case 'verified':
        return <AppBadge text="Verified" icon="check" />;
      case 'rejected':
        return <AppBadge text="Rejected" icon="times" type="danger" />;
      case 'unverified':
        return <AppBadge text="Unverified" icon="times" type="danger" />;
      case 'verification_in_progress':
        return (
          <AppBadge
            text="Verification in progress"
            icon="clock-o"
            type="info"
          />
        );
      case 'pending_frontend_verification':
        return (
          <AppBadge text="Review in progress" icon="clock-o" type="info" />
        );
      case 'referred':
        return (
          <AppBadge
            text="Referred"
            icon="clock-o"
            type="warning"
            style={{ color: 'white' }}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="users"
          title="Users"
          onInit={tableComponent => (this._tableComponent = tableComponent)}
          // headerActions={
          //   <Link
          //     to="/users/all-users/add-new"
          //     className="button width-240"
          //     style={{ marginRight: '10px' }}
          //   >
          //     <i className="fa fa-fw fa-plus" />
          //     Add new
          //   </Link>
          // }
          filter={[
            {
              name: 'term',
              type: 'term'
            }
          ]}
          order={[
            {
              field: 'id',
              direction: 'desc'
            }
          ]}
          model={UsersModel}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'kycStatus':
                return this._renderStatus(item);
              case 'userStatus':
                return this._renderEnableDisableSwitch(item);
              case 'additionalData.associateType':
                return item.additionalData?.userType
                  ? capitalize(item.additionalData?.userType)
                  : '';
              case 'organisationId':
                return item.organisationId ? (
                  <Link
                    to={`/users/organisations/${item.organisationId}`}
                    className="button-info"
                    style={
                      item.organisationName && item.organisationName.length > 17
                        ? { minWidth: 190 }
                        : {}
                    }
                  >
                    {item.organisationName
                      ? `${
                          item.organisationName.length > 17
                            ? `${item.organisationName.substring(0, 15)}...`
                            : item.organisationName
                        }`
                      : 'Info'}
                  </Link>
                ) : (
                  ''
                );
              // case 'roles':
              //   return this._renderUserRole(item);
              default:
            }
          }}
          getColumnActions={(member, index) => {
            const userName =
              member.firstName && member.lastName
                ? `${member.firstName} ${member.lastName}`
                : member.email;
            return (
              <div style={{ minWidth: 150 }}>
                <Link to={`/user-details/${member.id}`} className="button-info">
                  Details
                </Link>
                <DeleteButton
                  onConfirm={() => this.onDeleteItem(member.id)}
                  text={`Are you sure you wish to delete user "${`${userName}`}"?`}
                />
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>
      </>
    );

    if (this.props.justTable) {
      return inner;
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-users" aria-hidden="true" /> Users
        </h1>
        {inner}
      </div>
    );
  }
}
